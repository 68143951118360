import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import BaseRoute from './helpers/BaseRoute'
import Form from './features/cadastro/Form'
import FormFim from './features/cadastro/FormFim';
import LoginPage from './features/login/LoginPage';
import Cadastrados from './features/cadastrados/Cadastrados';

function App() {
  return (
    <Router>
      <Switch>
        <BaseRoute exact path="/">
          <Form />
        </BaseRoute>
        <BaseRoute path="/cadastro-fim">
          <FormFim />
        </BaseRoute>
        <BaseRoute path="/login">
          <LoginPage />
        </BaseRoute>
        <BaseRoute path="/cadastros">
          <Cadastrados />
        </BaseRoute>
      </Switch>
    </Router>
  );
}

export default App;
