import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiUrl } from '../../helpers/api'

const initialState = {
  register: {},
  isFetching: false,
  hasError: false,
  listaSecretaria : []
}

export const getRegisterCpf = createAsyncThunk(
  'register/getRegisterCpf',
  async (cpf, thunkAPI) => {
    try {
      const response = await fetch(`https://formsocialapi.anapolis.go.gov.br/db2/busca/getFuncionariosCPF/${cpf}`);
      let data = await response.json();
      if (response.status === 200) {
        return data[0];
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log('Error', e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

export const postRegister = createAsyncThunk(
  'register/postRegister',
  async (dadosRegister, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${apiUrl}register`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...dadosRegister }),
        }
      );
      let data = await response.json();
      if (response.status === 201) {
        return data;
      } else {
        if (response.status === 422) {
          return rejectWithValue(data);
        }
        if (response.status === 400) {
          return rejectWithValue(data);
        }
      }
    } catch (e) {
      if (!e.response) {
        throw e
      }

      return rejectWithValue(e.response.data)
    }
  }
);

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    setRegister: (state, action) => {
      const { payload } = action
      return state = {
        ...state,
        register: {
          ...state.register,
          ...payload
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRegisterCpf.pending, (state) => {
        state.isFetching = true;
        state.hasError = false;
      })
      .addCase(getRegisterCpf.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.register = action.payload;
        state.listaSecretaria.push({
          cpf: action.payload.cpf,
          secretaria: action.payload.un_of,
          nome: action.payload.nome
        })
      })
      .addCase(getRegisterCpf.rejected, (state) => {
        state.isFetching = false;
        state.hasError = true;
      })
      .addCase(postRegister.pending, (state) => {
        state.isFetching = true;
        state.hasError = false;
      })
      .addCase(postRegister.fulfilled, (state, action) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.register = action.payload;
      })
      .addCase(postRegister.rejected, (state) => {
        state.isFetching = false;
        state.hasError = true;
      })
  },
});

export const selectRegister = (state) => state.register

export const {
  setRegister
} = registerSlice.actions
export default registerSlice.reducer