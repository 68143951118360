import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {apiUrl} from '../../helpers/api'

const initialState = {
  name: '',
  email: '',
  token: '',
  role: [],
  message: ''
};

export const postLogin = createAsyncThunk(
  'login/postLogin',
  async (dadosLogin, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${apiUrl}auth/login`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...dadosLogin }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        localStorage.setItem('token', data.tokens.access.token)
        return data.user;
      } else { 
        if (response.status === 422) {
          return rejectWithValue(data);
        }
        if (response.status === 400) {
          return rejectWithValue(data);
        }
        if (response.status === 401) {
          return rejectWithValue(data);
        }
      }
    } catch (e) {
      if (!e.response) {
        throw e
      }

      return rejectWithValue(e.response.data)
    }
  }
);

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postLogin.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postLogin.fulfilled, (state, action) => {
        state.name = action.payload
        state.email = action.payload.email
        state.token = action.payload.token
        state.role = action.payload.role
        
      })
      .addCase(postLogin.rejected, (state, action) => {
        state.message = action.payload
      });
  },
});

// export const {} = loginSlice.actions;

export default loginSlice.reducer;
