// import { Typography } from '@mui/material';
import moment from "moment";
import MUIDataTable from "mui-datatables";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from 'react-router-dom';
import {
  getCadastrados,
  putCadastradoSecretaria,
  selectCadastrados,
} from "./cadastradosSlice";
import { saveAs } from "file-saver";
import { write, utils } from "xlsx";
import { getRegisterCpf, selectRegister } from "../cadastro/registerSlice";

export default function Cadastrados() {
  const dispatch = useDispatch();
  const { cadastros } = useSelector(selectCadastrados);

  console.log(cadastros);

  useEffect(() => {
    dispatch(getCadastrados());
  }, [dispatch]);

  const columns = [
    {
      name: "numeroInscricao",
      label: "Numero de Inscrição",
      options: {
        filter: true,
        display: "false",
        sort: true,
      },
    },
    {
      name: "matricula",
      label: "Matricula",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "nome",
      label: "Nome",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "cpf",
      label: "CPF",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "secretaria",
      label: "Secretaria",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "uf_rg",
      label: "UF",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "curso",
      label: "Curso",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "telefone",
      label: "Fone",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "dt_nascimento",
      label: "Data Nascimento",
      options: {
        filter: true,
        display: "false",
        sort: true,
        customBodyRender: (value) => moment(value).format("DD/MM/YYYY"),
      },
    },
    {
      name: "createdAt",
      label: "Data do Cadastro",
      options: {
        filter: true,
        display: "false",
        sort: true,
        customBodyRender: (value) => moment(value).format("DD/MM/YYYY"),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    onDownload: (buildHead, buildBody, columns, values) => {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const json = values.reduce((result, val) => {
        const temp = {};
        val.data.forEach((v, idx) => {
          temp[columns[idx].name] = v;
        });

        //Deletando coluno createdAt e numeroInscricao
        delete temp.createdAt;
        delete temp.numeroInscricao;

        //formatando data para DD/MM/YYYY
        temp.dt_nascimento = moment(temp.dt_nascimento).format("DD/MM/YYYY");

        result.push(temp);
        return result;
      }, []);

      const fileName = `Cadastros Curso`;
      const ws = utils.json_to_sheet(json);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      saveAs(data, fileName + fileExtension);
      // cancel default  CSV download from table
      return false;
    },
    textLabels: {
      toolbar: {
        downloadCsv: "Baixar Excel",
      },
    },
  };

  return (
    <div>
      <MUIDataTable
        title={"Lista de Matriculados"}
        data={cadastros}
        columns={columns}
        options={options}
      />
    </div>
  );
}
