import React from 'react'
import { Route } from 'react-router-dom'
import BaseLayout from '../features/layout/baseLayout/BaseLayout'

export default function BaseRoute({ children, ...rest }) {
  return (
    <Route {...rest} render={() =>
      <BaseLayout> {children} </BaseLayout>
    } />
  )
}
