import { configureStore } from '@reduxjs/toolkit';
import registerReducer from '../features/cadastro/registerSlice';
import loginReducer from '../features/login/loginSlice'
import cadastradosReducer from '../features/cadastrados/cadastradosSlice';

export const store = configureStore({
  reducer: {
    register: registerReducer,
    cadastrados: cadastradosReducer,
    login: loginReducer
  },
});
