import { AppBar, Button,  Grid } from '@material-ui/core'
// import { Box, CardActionArea } from '@material-ui/core'
import React from 'react'
// import { useHistory } from 'react-router-dom'

export default function Header() {
  // const history = useHistory()
  return (
    <AppBar  >
      <Grid container align="center" style={{ background: '#0E2A43', height: '5vh', color: 'white' }}>
        <Grid item xs={12} >
          <Button href="http://anapoliseam.nucleo.site/" className="button-regulamento">Retornar</Button>
        </Grid>
      </Grid>
    </AppBar>
  )
}
