import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
// import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectRegister } from './registerSlice';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  campos: {
    marginTop: theme.spacing(1),
    borderBottom: 'solid'
  },
  titulo: {
    fontWeight: 550,
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  title: {
    marginTop: theme.spacing(2)
  },
}));



export default function FormFim() {
  const classes = useStyles();
  // const history = useHistory()

  const { register } = useSelector(selectRegister)


  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <React.Fragment>
            <React.Fragment >
              <Grid container spacing={2} >
                <Grid className={classes.campos} align="center" item xs={12} >
                  <img style={{ width: '100%' }} alt="logo anápolis" src="https://cdn.anapolis.go.gov.br/img/eam/logo_eam_azul.jpg" />
                </Grid>
              </Grid>
              <Grid container spacing={2} >
                <Grid className={classes.campos} align="center" item xs={12} >
                  <Typography variant="h5" > Comprovante de Inscrição </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} >
                <Grid className={classes.campos} align="center" item xs={3} >
                  <Typography className={classes.titulo} > Nome </Typography>
                  <Typography> {register.nome} </Typography>
                </Grid>
                <Grid className={classes.campos} align="center" item xs={3} >
                  <Typography className={classes.titulo} > Secretaria </Typography>
                  <Typography> {register.secretaria} </Typography>
                </Grid>
                <Grid className={classes.campos} align="center" item xs={3} >
                  <Typography className={classes.titulo} > CPF </Typography>
                  <Typography> {register.cpf} </Typography>
                </Grid>
                <Grid className={classes.campos} align="center" item xs={3} >
                  <Typography className={classes.titulo} > Matrícula </Typography>
                  <Typography> {register.matricula} </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} >
                <Grid className={classes.campos} align="center" item xs={3} >
                  <Typography className={classes.titulo} > Data de Nascimento </Typography>
                  <Typography> {moment(register.dt_nascimento).utc().format('DD/MM/YYYY')} </Typography>
                </Grid>
                <Grid className={classes.campos} align="center" item xs={3} >
                  <Typography className={classes.titulo} > Curso </Typography>
                  <Typography> {register.curso} </Typography>
                </Grid>
                <Grid className={classes.campos} align="center" item xs={3} >
                  <Typography className={classes.titulo} > Período de Realização </Typography>
                  <Typography> A definir </Typography>
                </Grid>
                <Grid className={classes.campos} align="center" item xs={3} >
                  <Typography className={classes.titulo} > Acesse </Typography>
                  <Typography> Plataforma de Ensino </Typography>
                </Grid>
              </Grid>
            </React.Fragment>
          </React.Fragment>
        </Paper>
      </main>
    </React.Fragment>
  );
}