import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {apiUrl} from '../../helpers/api'

const initialState = {
  cadastros: [],
  id: '',
  name: '',
  email: '',
  fone: '',
  cpf: '',
  curso: '',
  address: {},
  dataNascimento: '',
  rg: '',
  ufRg: '',
  matricula: '',
  numeroInscricao: '',
  createdAt: '',
};

export const getCadastrados = createAsyncThunk(
  'cadastrados/getCadastrados',
  async () => {
    const data = await fetch(`${apiUrl}register`,
      {
        "headers": {
          'authorization': `Bearer ${localStorage.getItem("token")}`
        }
      });
    const json = await data.json();
    return json.results;
  }
);

export const putCadastradoSecretaria = createAsyncThunk(
  'cadastrados/putCadastradoSecretaria',
  async (cadastrados, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${apiUrl}register`,
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ cadastrados, }),
        }
      );
      let data = await response.json();
      if (response.status === 201) {
        return data;
      } else {
        if (response.status === 422) {
          return rejectWithValue(data);
        }
        if (response.status === 400) {
          return rejectWithValue(data);
        }
      }
    } catch (e) {
      if (!e.response) {
        throw e
      }

      return rejectWithValue(e.response.data)
    }
  }
);

const cadastradosSlice = createSlice({
  name: 'cadastrados',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCadastrados.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCadastrados.fulfilled, (state, action) => {
        state.cadastros = action.payload
      })
      .addCase(getCadastrados.rejected, (state, action) => {
        state.message = action.payload
      });
  },
});

export const selectCadastrados = (state) => state.cadastrados

// export const {} = cadastradosSlice.actions;

export default cadastradosSlice.reducer;
