import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useDispatch, useSelector } from "react-redux";
import {
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { getRegisterCpf, postRegister, selectRegister } from "./registerSlice";
// import {  setCadastro } from './cadastroSlice';
import { useHistory } from "react-router-dom";
// import {  useParams } from 'react-router-dom';
import NumberFormat from "react-number-format";
import moment from "moment";
import { cpf } from "cpf-cnpj-validator";
import toast, { Toaster } from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  helper: {
    color: "red",
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(9),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    color: "white",
    marginLeft: theme.spacing(1),
    background:
      "linear-gradient(13deg, rgb(240, 180, 30) 0%, rgb(232, 150, 27) 100%)",
  },
}));

export default function FormCadastro() {
  const [funcaoRequired, setFuncaoRequired] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    control,
    setValue,
    watch,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const { register } = useSelector(selectRegister);
  const watchCpf = watch("cpf");
  const watchCurso = watch("curso");

  useEffect(() => {
    if (register.nome_funcionario) {
      setValue("nome", register.nome_funcionario, { shouldDirty: true });
      setValue("secretaria", register.un_of, { shouldDirty: true });
      setValue("unidExercicio", register.un_ex, { shouldDirty: true });
      setValue("matricula", register.matricula, { shouldDirty: true });
      setValue("email", register.email, { shouldDirty: true });
      setValue("telefone", register.celular, { shouldDirty: true });
      setValue("dt_nascimento", register.dt_nascimento, { shouldDirty: true });
      setValue("rg", register.rg, { shouldDirty: true });
      setValue("uf_rg", register.rg_sigla, { shouldDirty: true });
      setValue("cep", register.cep, { shouldDirty: true });
      setValue("rua", register.rua, { shouldDirty: true });
      setValue("numero", register.numero, { shouldDirty: true });
      setValue("complemento", register.complemento, { shouldDirty: true });
      setValue("bairro", register.bairro, { shouldDirty: true });
      setValue("cidade", register.cidade, { shouldDirty: true });
      setValue("uf", register.uf, { shouldDirty: true });
    }
  }, [register, setValue]);

  const onSubmit = (data) => {
    data.dt_nascimento = moment(data.dt_nascimento, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    );

    dispatch(postRegister(data))
      .unwrap()
      .then((originalPromiseResult) => {
        history.push("/cadastro-fim");
      })
      .catch((rejectedValueOrSerializedError) => {
        toast.error(rejectedValueOrSerializedError.message, {
          duration: 10000,
        });
      });
  };

  const getRegister = () => {
    trigger("cpf");
    if (cpf.isValid(watchCpf)) {
      dispatch(getRegisterCpf(watchCpf));
    }
  };

  if (watchCurso === "Gestão de Conflitos e Liderança") {
    if (!funcaoRequired) {
      setFuncaoRequired(true);
    }
  }

  return (
    <React.Fragment>
      <Toaster />
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Cadastro
          </Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <hr></hr>
            <React.Fragment>
              <Typography variant="h6" gutterBottom>
                Informações Pessoais
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="cpf"
                    control={control}
                    rules={{
                      required: "CPF não pode ficar em branco",
                      validate: (value) => cpf.isValid(value) || "cpf inválido",
                    }}
                    render={({ field }) => (
                      <NumberFormat
                        customInput={TextField}
                        {...field}
                        fullWidth
                        format="###.###.###-##"
                        error={!!errors.cpf}
                        helperText={errors.cpf?.message}
                        onBlur={getRegister}
                        id="cpf"
                        name="cpf"
                        label="CPF"
                        required
                        mask="_"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="nome"
                    defaultValue=""
                    control={control}
                    rules={{ required: "Nome não pode ficar em branco" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!errors.nome}
                        helperText={errors.nome?.message}
                        id="nome"
                        name="nome"
                        label="Nome"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="secretaria"
                    defaultValue=""
                    control={control}
                    rules={{ required: "Secretaria não pode ficar em branco" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!errors.secretaria}
                        helperText={errors.secretaria?.message}
                        id="secretaria"
                        name="secretaria"
                        label="Secretaria"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="unidExercicio"
                    defaultValue=""
                    control={control}
                    rules={{
                      required: "Unidade de Exercício não pode ficar em branco",
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!errors.unidExercicio}
                        helperText={errors.unidExercicio?.message}
                        id="unidExercicio"
                        name="unidExercicio"
                        label="Unidade de Exercício"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="matricula"
                    defaultValue=""
                    control={control}
                    rules={{ required: "Nome não pode ficar em branco" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!errors.matricula}
                        helperText={errors.matricula?.message}
                        id="matricula"
                        name="matricula"
                        label="Matrícula"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="telefone"
                    control={control}
                    rules={{
                      required: "Telefone não pode ficar em branco",
                      minLength: {
                        value: 11,
                        message: "Coloque Número de celular com DDD",
                      },
                    }}
                    render={({ field }) => (
                      <NumberFormat
                        customInput={TextField}
                        {...field}
                        fullWidth
                        format="(##) #####-####"
                        error={!!errors.telefone}
                        helperText={errors.telefone?.message}
                        id="telefone"
                        name="telefone"
                        required
                        label="WhatsApp"
                        mask="_"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="rg"
                    defaultValue=""
                    control={control}
                    rules={{ required: "RG não pode ficar em branco" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!errors.rg}
                        helperText={errors.rg?.message}
                        id="rg"
                        required
                        name="rg"
                        label="RG"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel id="uf_rg">UF do RG</InputLabel>
                  <Controller
                    render={({ field }) => (
                      <Select {...field} fullWidth>
                        <MenuItem value={"AC"}>Acre</MenuItem>
                        <MenuItem value={"AL"}>Alagoas</MenuItem>
                        <MenuItem value={"AP"}>Amapá</MenuItem>
                        <MenuItem value={"AM"}>Amazonas</MenuItem>
                        <MenuItem value={"BA"}>Bahia</MenuItem>
                        <MenuItem value={"CE"}>Ceará</MenuItem>
                        <MenuItem value={"DF"}>Distrito Federal</MenuItem>
                        <MenuItem value={"ES"}>Espírito Santo</MenuItem>
                        <MenuItem value={"GO"}>Goiás</MenuItem>
                        <MenuItem value={"MA"}>Maranhão</MenuItem>
                        <MenuItem value={"MT"}>Mato Grosso</MenuItem>
                        <MenuItem value={"MS"}>Mato Groso do Sul</MenuItem>
                        <MenuItem value={"MG"}>Minas Gerais</MenuItem>
                        <MenuItem value={"PA"}>Pará</MenuItem>
                        <MenuItem value={"PB"}>Paraíba</MenuItem>
                        <MenuItem value={"PR"}>Paraná</MenuItem>
                        <MenuItem value={"PE"}>Pernambuco</MenuItem>
                        <MenuItem value={"PI"}>Piauí</MenuItem>
                        <MenuItem value={"RJ"}>Rio de Janeiro</MenuItem>
                        <MenuItem value={"RN"}>Rio Grande do Norte</MenuItem>
                        <MenuItem value={"RS"}>Rio Grande do Sul</MenuItem>
                        <MenuItem value={"RO"}>Rondônia</MenuItem>
                        <MenuItem value={"RR"}>Roraima</MenuItem>
                        <MenuItem value={"SC"}>Santa Catarina</MenuItem>
                        <MenuItem value={"SP"}>São Paulo</MenuItem>
                        <MenuItem value={"SE"}>Sergipe</MenuItem>
                        <MenuItem value={"TO"}>Tocantins</MenuItem>
                      </Select>
                    )}
                    control={control}
                    name="uf_rg"
                    id="uf_rg"
                    defaultValue=""
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="dt_nascimento"
                    control={control}
                    rules={{
                      required: "Data de Nascimento não pode ficar em branco",
                    }}
                    render={({ field }) => (
                      <NumberFormat
                        customInput={TextField}
                        {...field}
                        fullWidth
                        format="##/##/####"
                        error={!!errors.dt_nascimento}
                        helperText={errors.dt_nascimento?.message}
                        id="dt_nascimento"
                        name="dt_nascimento"
                        label="Data de Nascimento"
                        required
                        mask="_"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: "E-mail não pode ficar em branco",
                      // pattern: {
                      //   value:
                      //     /^[a-z0-9](\.?[a-z0-9]){5,}@g(oogle)?mail\.com$/i,
                      //   message: "Insira um e-mail do GMAIL válido.",
                      // },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        label="E-mail"
                        type="email"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel id="curso">Curso</InputLabel>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        fullWidth
                        error={!!errors.curso}
                        helperText={errors.curso?.message}
                        required
                      >
                        <MenuItem value={"Conhecendo a Administração Pública"}>
                          conhecendo a Administração Pública
                        </MenuItem>
                        <MenuItem value={"seminário-Liderança Transformadora"}>
                          seminário-Liderança Transformadora
                        </MenuItem>
                      </Select>
                    )}
                    control={control}
                    rules={{ required: "Curso não pode ficar em branco" }}
                    name="curso"
                    id="curso"
                    defaultValue=""
                  />
                  {!!errors.curso && (
                    <FormHelperText className={classes.helper}>
                      {errors.curso?.message}
                    </FormHelperText>
                  )}
                </Grid>
                {funcaoRequired && (
                  <Grid item xs={12} sm={4}>
                    <InputLabel id="funcao">Função</InputLabel>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          fullWidth
                          error={!!errors.funcao}
                          helperText={errors.funcao?.message}
                          required
                        >
                          <MenuItem value={"Chefia de Gabinete"}>
                            Chefia de Gabinete
                          </MenuItem>
                          <MenuItem value={"Coordenador"}>Coordenador</MenuItem>
                          <MenuItem value={"Diretor"}>Diretor</MenuItem>
                          <MenuItem value={"Gerente"}>Gerente</MenuItem>
                        </Select>
                      )}
                      control={control}
                      rules={{
                        required: "Este curso requer uma dessas funções",
                      }}
                      name="funcao"
                      id="funcao"
                      defaultValue=""
                    />
                    {!!errors.funcao && (
                      <FormHelperText className={classes.helper}>
                        {errors.funcao?.message}
                      </FormHelperText>
                    )}
                  </Grid>
                )}
              </Grid>
            </React.Fragment>
            <React.Fragment>
              <br />
              <Typography variant="h6" gutterBottom>
                Endereço
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="cep"
                    control={control}
                    rules={{ required: "requerido" }}
                    render={({ field }) => (
                      <NumberFormat
                        customInput={TextField}
                        {...field}
                        fullWidth
                        format="#####-###"
                        error={!!errors.cep}
                        helperText={errors.cep?.message}
                        id="cep"
                        name="cep"
                        label="CEP"
                        mask="_"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="rua"
                    defaultValue=""
                    control={control}
                    rules={{ required: "Rua não pode ficar em branco" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!errors.rua}
                        helperText={errors.rua?.message}
                        id="rua"
                        name="rua"
                        label="Rua"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="numero"
                    defaultValue=""
                    control={control}
                    rules={{ required: "Nome não pode ficar em branco" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!errors.numero}
                        helperText={errors.numero?.message}
                        id="numero"
                        name="numero"
                        label="Número"
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="complemento"
                    defaultValue=""
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!errors.complemento}
                        helperText={errors.complemento?.message}
                        id="complemento"
                        name="complemento"
                        label="Complemento"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="bairro"
                    defaultValue=""
                    control={control}
                    rules={{ required: "Nome não pode ficar em branco" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!errors.bairro}
                        helperText={errors.bairro?.message}
                        required
                        id="bairro"
                        name="bairro"
                        label="Bairro"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="cidade"
                    defaultValue=""
                    control={control}
                    rules={{ required: "Cidade não pode ficar em branco" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!errors.cidade}
                        helperText={errors.cidade?.message}
                        required
                        id="cidade"
                        name="cidade"
                        label="Cidade"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="uf"
                    defaultValue=""
                    control={control}
                    rules={{ required: "Estado não pode ficar em branco" }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        error={!!errors.uf}
                        helperText={errors.uf?.message}
                        required
                        id="uf"
                        name="uf"
                        label="Estado"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <div className={classes.buttons}>
                <Button
                  variant="contained"
                  className={classes.button}
                  type="submit"
                >
                  Próximo
                </Button>
              </div>
            </React.Fragment>
          </form>
        </Paper>
      </main>
    </React.Fragment>
  );
}
