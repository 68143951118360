import React from 'react'
import Header from './Header'

export default function BaseLayout({ children }) {
  return (
    <div className="default-layout" >
      <header className="header mb-2" >
        <Header />
      </header>
      <div style={{marginTop: '25px'}}>
        {children}
      </div>
    </div>
  )
}
